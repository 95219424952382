<template>
  <div class="container">
<!--    <c-button-->
<!--        aria-label="filter"-->
<!--        @click="openFilterModal"-->
<!--        variant-color="vc-orange"-->
<!--        variant="outline"-->
<!--        right-icon="filter"-->
<!--        size="sm"-->
<!--        position="absolute"-->
<!--        top="18"-->
<!--        right="20"-->
<!--    >-->
<!--      Filter-->
<!--    </c-button>-->

    <!-- Filter Dialog -->
    <c-modal
        :is-open="isFilterModalOpen"
        @close="closeFilterModal"
        :closeOnOverlayClick="false"
        size="lg"
        is-centered
        class="rounded-lg"
    >

      <c-modal-content>
        <!-- Modal Header -->
        <c-modal-header>Filter Options</c-modal-header>
        <c-modal-close-button @click="closeFilter" />
        <c-modal-body>
          <!-- Filter Checklist -->
          <c-form-control>
            <c-form-label>Select Filters</c-form-label>
            <c-checkbox-group v-model="selectedFilters">
              <c-checkbox class="dark:bg-pink-600" value="name">Name</c-checkbox>
              <c-checkbox variant-color="red" value="date">Date Range</c-checkbox>
              <c-checkbox variant-color="red" value="location">Location</c-checkbox>
            </c-checkbox-group>
          </c-form-control>

          <c-divider my="6" v-if="selectedFilters.length !== 0" color="gray"></c-divider>

          <!-- Filter by Name -->
          <c-form-control my="4" v-if="selectedFilters.includes('name')">
            <c-form-label>Filter by Name</c-form-label>
            <c-input placeholder="Enter Company Name" v-model="filterOptions.name" />
          </c-form-control>

          <!-- Date Range Filter -->
          <c-form-control my="4" v-if="selectedFilters.includes('date')">
            <c-form-label>Filter by Date Range</c-form-label>
            <c-stack direction="row" spacing="4">
              <c-input type="date" v-model="filterOptions.startDate" />
              <c-input type="date" v-model="filterOptions.endDate" />
            </c-stack>
          </c-form-control>

          <!-- Filter by Location -->
          <c-form-control my="4" v-if="selectedFilters.includes('location')">
            <c-form-label>Filter by Location</c-form-label>
            <c-input placeholder="Enter Location" v-model="filterOptions.location" />
          </c-form-control>
        </c-modal-body>

        <c-divider mt="7" color="gray"></c-divider>

        <!-- Modal Footer -->
        <c-modal-footer>
          <c-button variant-color="vc-orange" @click="applyFilters" mr="3">
            Apply
          </c-button>
          <c-button @click="clearFilters" variant="outline">
            Clear
          </c-button>
        </c-modal-footer>
      </c-modal-content>
      <!-- Modal Body -->
    </c-modal>

    <div
        class="mb-7"
    >
      <div class="flex flex-wrap">
        <c-box
            rounded="sm"
            bg="gray.100"
            shadow="sm"
            px="5"
            mt="5"
            mr="2"
            flex="1"
        >
          <c-flex
              justify="start"
              align-items="center"
              my="5"
              wrap="wrap"
          >
            <data-card class="mr-3 mb-3" :title="proCounts ?? 0" :subtitle="'Pro Plans'"></data-card>
            <data-card class="mr-3 mb-3" :title="premiumCounts ?? 0" :subtitle="'Premium Plans'"></data-card>
            <data-card class="mr-3 mb-3" :title="freemiumCounts ?? 0" :subtitle="'Freemium Plans'"></data-card>
          </c-flex>
        </c-box>
        <c-box
            flex="1"
            rounded="sm"
            shadow="sm"
            bg="gray.100"
            px="5"
            ml="2"
            mt="5"
        >
          <c-box
              my="5"
          >
            <c-text
                fontWeight="600"
                color="#616D7E"
            >
              Your referral link
            </c-text>
            <c-flex
                justify="start"
                align="center"
            >
              <c-flex
                  flex="1"
                  justify="start"
                  align="center"
                  bg="white"
                  mr="3"
                  borderRadius="md"
              >
                <c-text
                    flex="1"
                    my="2"
                    p="2"
                    color="#616D7E"
                    fontWeight="500"
                >
                  {{`https://app.vibrantcreator.com/auth/signup?referral_code=${company.referralCode}`}}
                </c-text>
                <c-icon-button
                    @click="copyReferralLink"
                    aria-label="copy"
                    size="md"
                    mr="3"
                    color="#616D7E"
                    icon="copy"
                />
              </c-flex>
              <c-button
                  @click="shareReferralLink"
                  aria-label="share"
                  size="md"
                  mr="3"
                  variant="solid"
                  variant-color="vc-orange"
                  icon="share"
              >
                Share
              </c-button>
            </c-flex>
          </c-box>
        </c-box>
      </div>
      <c-flex mt="4" h="450px" justify="space-between">
        <c-box
          flex="1"
          class="chart_div"
          bg="gray.100"
          rowSpan="3"
          colSpan="2"
          mr="2"
          shadow="sm"
          p="5"
        >
        <earnings-summary-card />
        </c-box>
        <c-box
          flex="1"
          bg="gray.100"
          rowSpan="3"
          colSpan="2"
          shadow="sm"
          ml="2"
          p="5"
        >
        <referral-businesses-card />
        </c-box>
      </c-flex>
      <c-flex mt="4" justify="space-between">
        <c-box
          flex="1"
          mr="2"
          bg="gray.100"
          shadow="sm"
          p="5"
      >
        <invoice-summary-card :invoice-data="invoiceData" />
      </c-box>
      <c-box
          flex="1"
          ml="2"
          bg="gray.100"
          shadow="sm"
          p="5"
      >
        <invite-businesses-card />
      </c-box>
      </c-flex>
    </div>

  </div>
</template>

<script>
import DataCard from "@/views/Affiliate/components/DataCard.vue";
import EarningsSummaryCard from "./EarningsSummaryCard.vue";
import ReferralBusinessesCard from "@/views/Affiliate/dashboard/ReferralBusinessesCard.vue";
import InvoiceSummaryCard from "@/views/Affiliate/dashboard/InvoiceSummaryCard.vue";
import InviteBusinessesCard from "@/views/Affiliate/dashboard/InviteBusinessesCard.vue";
import {mapGetters} from "vuex";
import {
  getAppliedCouponForAffiliate,
  getFreemiumReferralBusinesses,
  getProOrPremiumReferralBusinesses
} from "@/services/company";

export default {
  name: "Index",
  components: {
    DataCard,
    EarningsSummaryCard,
    ReferralBusinessesCard,
    InvoiceSummaryCard,
    InviteBusinessesCard
  },

  computed: {
    ...mapGetters({
      user: "auth/getUser",
      company: "company/getActiveCompany"
    }),
    app_url(){
      return process.env.VUE_APP_LINK
    }
  },

  methods: {
    allProSubscribers() {
      try {
        getProOrPremiumReferralBusinesses(
            this.company.id,
            process.env.VUE_APP_PLAN_PRO_MONTHLY,
            process.env.VUE_APP_PLAN_PRO_YEARLY
        )
            .subscribe({
              next: (res) => {
                this.proCounts = res.data.company_referral_aggregate.aggregate.count;
              },
              error: (error) => {
                console.error("Subscription error:", error);
              }
            });
      } catch (e) {
        console.error("Unexpected error:", e);
      }
    },

    allPremiumSubscribers() {
      try {
        getProOrPremiumReferralBusinesses(
            this.company.id,
            process.env.VUE_APP_PLAN_PREMIUM_MONTHLY,
            process.env.VUE_APP_PLAN_PREMIUM_YEARLY
        )
            .subscribe({
              next: (res) => {
                this.premiumCounts = res.data.company_referral_aggregate.aggregate.count;
              },
              error: (error) => {
                console.error("Subscription error:", error);
              }
            });
      } catch (e) {
        console.error("Unexpected error:", e);
      }
    },

    allFreemiumSubscribers() {
      try {
        getFreemiumReferralBusinesses(
            this.company.id
        )
            .subscribe({
              next: (res) => {
                console.log('gRXusty gun ==:', res)
                this.freemiumCounts = res.data.company_referral_aggregate.aggregate.count;
              },
              error: (error) => {
                console.error("Subscription error:", error);
              }
            });
      } catch (e) {
        console.error("Unexpected error:", e);
      }
    },

    getInvoiceData(){
      try{
        this.getAppliedCouponsData()
            .then((response) => {
              if(response.success){
                this.invoiceData = response.data
                console.log('Datax here', this.invoiceData)
              }
              else{
                console.log('Something went wrong')
              }
            })
      }catch (e){
        console.log("Something went wrong. Please try again.")
      }

    },
    async getAppliedCouponsData(){
      try {
        const response = await getAppliedCouponForAffiliate(this.company.id, 10, 0)
        return { success: true, data: response.data.coupon_company }
      }catch (e) {
        return { success: false }
      }
    },
    copyReferralLink() {
      const referralLink = `https://app.vibrantcreator.com/auth/signup?referral_code=${this.company.referralCode}`;
      navigator.clipboard.writeText(referralLink)
          .then(() => {
            this.$toast({
              title: 'Copied.',
              description: `Referral link has been successfully copied`,
              status: 'success',
              position: 'top',
              duration: 3000,
            });
          })
          .catch(err => {
            console.log(err)
            this.$toast({
              title: 'Failed to copy.',
              description: `Couldn't copy referral link, please try again.`,
              status: 'error',
              position: 'top',
              duration: 3000,
            });
          });
    },
    shareReferralLink() {
      const referralLink = `http://localhost:8080/auth/signup?referral_code=${this.company.referralCode}`;
      const shareData = {
        title: 'Referral Link',
        text: 'Check out this awesome referral!',
        url: referralLink
      };

      if (navigator.share) {
        navigator.share(shareData)
            .then(() => console.log('Referral link shared successfully'))
            .catch(err => console.error('Error sharing: ', err));
      } else {
        alert('Share feature not supported on this device.');
      }
    },
    openFilterModal() {
      this.isFilterModalOpen = true;
    },

    closeFilterModal() {
      this.isFilterModalOpen = false;
    },

    applyFilters() {
      this.closeFilterModal();
    },

    closeFilter() {
      // Close the filter modal
      this.closeFilterModal();
    },

    clearFilters() {
      // Implement logic to clear filters
      this.selectedFilters = [];
      this.filterOptions = {
        name: '',
        startDate: '',
        endDate: '',
        location: '',
        // Clear more filter options based on your tableColumns
      };
    },
  },

  data(){
    return {
      proCounts: null,
      premiumCounts: null,
      freemiumCounts: null,
      invoiceData: null,
      isFilterModalOpen: false,
      selectedFilters: [], // To store selected filters
      filterOptions: {
        name: '',
        startDate: '',
        endDate: '',
        location: '',
      },
      criteria: {
        title: 'Dashboard',
        description:
            "Visualize the impact of your organization's activities with our dashboard. Explore detailed updates and witness the real-time effects of your efforts.",
      },

      dummy: [
        {
          title: '58',
          subtitle: 'Freemium Signups',
          icon: "@/assets/icons/dashboard-line.svg#dashboard",
          cardColor: 'rgba(0,48,143,0.29)',
          textColor: '#00308F',
        },
        {
          title: '58',
          subtitle: 'Premium Signups',
          icon: "@/assets/icons/dashboard-line.svg#dashboard",
          cardColor: 'rgba(209,165,0,0.41)',
          textColor: '#D1A500',
        },
        {
          title: '58',
          subtitle: 'Enterprise Signups',
          icon: "@/assets/icons/dashboard-line.svg#dashboard",
          cardColor: 'rgba(251, 205, 190, 1)',
          textColor: '#F15425',
        }
      ],
    };
  },

  mounted() {
    this.getInvoiceData();
    this.allProSubscribers();
    this.allPremiumSubscribers();
    this.allFreemiumSubscribers();
  },
}
</script>

<style scoped>
.container{
  overflow-y: auto;
  height: 100vh;
}

.chart_div{
  height: 100%;
}

.wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>